import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "statistics"
    }}>{`Statistics`}</h1>
    <p>{`Query the city's statistical databases`}</p>
    <ul>
      <li parentName="ul">{`API location: `}<a parentName="li" {...{
          "href": "http://dev.hel.fi/stats/resources/"
        }}>{`dev.hel.fi/stats/resources/`}</a></li>
    </ul>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "http://dev.hel.fi/stats/"
      }}>{`API documentation`}</a>{` is primarily in Finnish. English descriptions are found below.`}</p>
    <p>{`More detailed information and installation instructions can be found in the Github page of statcubeserver, which is a thin wrapper around the datafile-handler pydatacube.`}</p>
    <p>{`Browse datasets with a browser: `}</p>
    <pre><code parentName="pre" {...{}}>{`    /browser/
`}</code></pre>
    <p>{`Preview a dataset and see available methods: `}</p>
    <pre><code parentName="pre" {...{}}>{`    /browser/?resource=[resource uri]
`}</code></pre>
    <p>{`Get available datasets: `}</p>
    <pre><code parentName="pre" {...{}}>{`    /resources/
`}</code></pre>
    <p>{`Get dataset metadata and methods: `}</p>
    <pre><code parentName="pre" {...{}}>{`    /resources/[resource id]
`}</code></pre>
    <p>{`Get a filtered dataset: `}</p>
    <pre><code parentName="pre" {...{}}>{`    /resources/[resource_id]/filter[&col1=cat1,cat2&col2=cat3 ...
`}</code></pre>
    <p>{`Get dataset's data (can be filtered) as "entries": `}</p>
    <pre><code parentName="pre" {...{}}>{`    /resources/[dataset path]/entrie
`}</code></pre>
    <p>{`Get data as "table": `}</p>
    <pre><code parentName="pre" {...{}}>{`    /resources/[dataset path]/table[?start=firstrow&end=lastrow
`}</code></pre>
    <p>{`Get data as "columns": `}</p>
    <pre><code parentName="pre" {...{}}>{`    /resources/[dataset path]/column
`}</code></pre>
    <p>{`Get grouped data ("pivot") as columns: `}</p>
    <pre><code parentName="pre" {...{}}>{`    /resources/[dataset path]/group_for_columns?as_values=[col1,col2...
`}</code></pre>
    <p>{`Get data as JSON-stat: `}</p>
    <pre><code parentName="pre" {...{}}>{`    /resources/[dataset path]/jsonsta
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      